import React from 'react';
import financial_charts from 'images/financial_charts.png'; 
import UsesSection from 'components/sections/UsesSection';




function Financial() {

    return (
        <UsesSection 
            title={"Finance"}
            content="Financial Page, Mostly Comapre"
            link_backward="/uses/clinician"
            link_forward="/uses/planner-designer"
            media={<div className="uses-video">
            <video
                className="uses-video"
                canplay="this.play()"
                onloadeddata="this.play();"
                playsinline
                id="info-video"
                title="Video"
                onclick="this.play()"
                controls="controls"
                loop
            >
                <source type="video/mp4" src="https://storage.googleapis.com/media-buckets-prod-forsightdigital-com/videos/Financial_Short.mp4"></source>
            </video>

        </div>}
        />
        
    )
}

export default Financial;